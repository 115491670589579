<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <v-img
          :src="require('@/assets/logo.png')"
          class="my-3 mb-1"
          contain
          height="350"
        />
        <v-card flat class="text-center" color="transparent">
          <p>Si quieres lucir más Bella estás en el lugar correcto 💅🏻💄🤩🤩</p>
        </v-card>
      </v-col>
      <v-col cols="12" >
        <FilterForm @filtros="updateFilters" :filters-applied="filtersApplied" />
        <div class="mt-2" v-if="!loading">Mostrando {{ numFilteredProducts }} de {{ numTotalProducts }} productos</div>
      </v-col>
      <v-col cols="6" sm="3" md="2" lg="2" xl="2" v-for="(product, index) in products" :key="index">
        <v-sheet min-height="250" class="fill-height" color="transparent">
          <v-lazy
            v-model="product.isActive" :options="{
              threshold: .1
            }">
            <v-card class="fill-height">
              <div @click="openProduct(product)" class="pointer">
                <v-img
                  :src="product.thumbnail_url"
                  class="my-3"
                  contain
                  width="100%"
                />
              </div>
              <v-card-text class="d-flex justify-space-between py-1 px-2">
                <div @click="openProduct(product)" class="pointer">
                  <h3 class="text-capitalize">{{ product.name.toLowerCase() }}</h3>
                  <h4 v-if="hash" class="green--text">${{ product.sale_price.toLocaleString('ES-co') }}</h4>
                  <div v-html="product.description"></div>
                </div>
                <v-btn icon outlined color="pink" @click="addToCart(product.id)">
                  <v-icon>mdi-cart-plus</v-icon>
                </v-btn>
              </v-card-text>
              <v-card-actions class="d-flex justify-space-between">
                <div v-if="product.tags" class="text-sm">
                  <span
                    class="pink--text tags"
                    v-for="(tag, idx) in product.tags"
                    :key="product.id + '-' + idx"
                  >
                    {{ tag.toLowerCase() }}<span v-if="idx+1 != product.tags.length">,</span>
                  </span>
                </div>
                <div class="text-xs">{{ product.reference }}</div>
              </v-card-actions>
            </v-card>
          </v-lazy>
        </v-sheet>
      </v-col>
      <v-col cols="12" class="text-center">
        <div class="mt-2" v-if="!loading">Mostrando {{ numFilteredProducts }} de {{ numTotalProducts }} productos</div>
      </v-col>
      <v-col cols="12" v-if="next && !loading" class="text-center pt-2">
        <v-btn color="primary" @click="getNext()">Mostrar más productos</v-btn>
      </v-col>
      <v-col cols="12" v-if="loading" class="text-center">
        <p>Cargando los productos... por favor espera un momento.</p>
        <v-progress-circular
          indeterminate
          color="primary"
        />
      </v-col>
      <v-col v-if="error">
        <v-alert text class="text-center">
          <p>
            Ups! Tenemos problemas cargando el catálogo de productos
            <v-icon>mdi-emoticon-sad-outline</v-icon>
          </p>
          <p>Por favor vuelve a intentar o escríbenos por <a :href="`https://wa.me/{whatsapp}`" target="_blank">whatsapp</a> <v-icon>mdi-whatsapp</v-icon></p>
        </v-alert>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogShowProduct"
      max-width="360"
    >
      <v-card v-if="selectedProduct">
        <div class="">
          <v-img
            :src="selectedProduct.image_url"
            contain
            width="100%"
          />
        </div>
        <v-card-text class="d-flex justify-space-between py-1 px-2">
          <div>
            <h3 class="text-capitalize">{{ selectedProduct.name.toLowerCase() }}</h3>
            <h4 v-if="hash" class="green--text">${{ selectedProduct.sale_price.toLocaleString('ES-co') }}</h4>
            <div v-html="selectedProduct.description"></div>
          </div>
          <v-btn icon @click="addToCart(selectedProduct.id)">
            <v-icon>mdi-cart-plus</v-icon>
          </v-btn>
        </v-card-text>
        <v-card-text class="py-1 px-2 d-flex justify-space-between">
          <div v-if="selectedProduct.tags" class="text-sm">
            <span
              class="pink--text tags"
              v-for="(tag, idx) in selectedProduct.tags"
              :key="selectedProduct.id + '-' + idx"
            >
              {{ tag.toLowerCase() }}<span v-if="idx+1 != selectedProduct.tags.length">,</span>
            </span>
          </div>
          <div class="text-xs">{{ selectedProduct.reference }}</div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="pink"
            text
            @click="dialogShowProduct = false"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import FilterForm from "@/components/FilterForm.vue"
  import Constants from "@/constants"

  import axios from "axios";
  import { mapMutations } from "vuex";

  export default {
    name: 'ProductList',
    components: {
      FilterForm,
    },

    data() {
      return {
        url: Constants.API_URL,
        username: Constants.USERNAME,
        whatsapp: Constants.WHATSAPP,
        products: [],
        error: false,
        loading: true,
        next: null,
        hash: "show_prices",
        dialogShowProduct: false,
        selectedProduct: null,
        cart: {},
        filtros: [],
        numTotalProducts: 0,
        filtersApplied: true
      }
    },
    computed: {
      tagIds() {
        return this.filtros.join(',');
      },
      numFilteredProducts() {
        return this.products.length;
      },
    },
    methods: {
      ...mapMutations({
        addToCart: "addToCart"
      }),
      getInitialProducts() {
        axios.get(`${this.url}/api/v1/catalogue/${this.username}/?limit=100&tag_ids=${this.tagIds}`).then((response) => {
          this.loading = false;
          this.products = response.data.results;
          this.next = response.data.next;
          this.numTotalProducts = response.data.count;
          this.filtersApplied = true;
        })
        .catch((error) => {
          console.error(error)
          this.error = true;
          this.loading = false;
        });
      },
      checkBottomOfWindow() {
        window.onscroll = () => {
          let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
          if (bottomOfWindow && this.next && !this.loading) {
            this.getNext();
          }
        }
      },
      getNext() {
        this.loading = true;
        axios.get(this.next).then(response => {
          this.products = this.products.concat(response.data.results);
          this.next = response.data.next;
          this.loading = false;
        });
      },
      openProduct(product) {
        this.selectedProduct = product;
        this.dialogShowProduct = true;
      },
      addToCart(product_id) {
        this.$store.commit("addToCart", product_id);
        this.$toasted.success('Agregado al carrito').goAway(1000);
      },
      updateFilters(data) {
        this.filtros = data;
        this.products = [];
        this.getInitialProducts();
      },
    },
    beforeMount() {
      this.getInitialProducts();
      const hash = new URL(location.href).hash;
      if (hash) {
        this.hash = hash;
      }
    },
    mounted() {
      this.checkBottomOfWindow();
    },
    watch: {
      products(products) {
        this.$store.commit("setProducts", products);
      },
    },
  }
</script>

<style scoped>
.text-xs {
  font-size: 0.65rem;
}
.text-sm {
  font-size: 0.8rem;
}
.pointer {
  cursor: pointer;
}
</style>
