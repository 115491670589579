<template>
  <v-app>
    <app-bar />
    <v-main>
      <ProductList />
    </v-main>
    <v-divider />
    <v-footer color="transparent">
      <v-container class="my-6">
        <div class="text-center mb-10">
          <div>
            <v-img
              alt="Distribuidora Un Mundo de Belleza"
              class=""
              style="margin:auto"
              :src="require('@/assets/logotipo.png')"
              width="100"
            />
          </div>
          <h2>Distribuidora Un Mundo de Belleza</h2>
        </div>
        <v-row>
          <v-col cols="12" md="6" class="text-center">
            <h3>¡Descuentos increíbles!</h3>
            <p>Maquillaje, Tintes, Tratamientos Capilares,<br />Keratinas, Accesorios de Uñas y más...</p>
          </v-col>
          <v-col>
            <h3 class="ml-3">Contacto</h3>
            <v-list class="transparent">
              <v-list-item class="">
                <p>Av Simón Bolivar Cra 16 #52-89<br />Dosquebradas, Risaralda, Colombia</p>
              </v-list-item>
              <v-list-item class="pl-0">
                <v-btn
                  :href="`https://wa.me/${whatsapp}`"
                  target="_blank"
                  text
                >
                  <v-icon class="mr-1">mdi-whatsapp</v-icon> +{whatsapp}
                </v-btn>
              </v-list-item>
              <v-list-item class="pl-0">
                <v-btn
                  href="https://www.instagram.com/distriunmundodebelleza/"
                  target="_blank"
                  text
                >
                  <v-icon class="mr-1">mdi-instagram</v-icon> distriunmundodebelleza
                </v-btn>
              </v-list-item>
              <v-list-item class="pl-0">
                <v-btn
                  href="https://www.facebook.com/Un-MUNDO-de-belleza-100649185230122"
                  target="_blank"
                  text
                >
                  <v-icon class="mr-1">mdi-facebook</v-icon> Un MUNDO de belleza
                </v-btn>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>

import AppBar from './components/AppBar';
import ProductList from './components/ProductList';
import Constants from './constants';

export default {
  name: 'App',
  data: () => ({
    whatsapp: Constants.WHATSAPP_NUMBER,
  }),
  onMounted() {
    console.log("App mounted", Constants.WHATSAPP_NUMBER)
  },
  components: {
    AppBar,
    ProductList,
  },
};
</script>


<style>
.v-application {
  font-family: "Quicksand", sans-serif;
  background-image: url("@/assets/backgrounds/bubbles.png") !important;
}
</style>