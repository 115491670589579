<template>
  <v-card>
    <v-card-text>
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="text-center">
            <v-btn
              color="pink"
              dark
              v-bind="attrs"
              v-on="on"
              class="justify-center"
            >
              <v-icon small>mdi-filter-variant</v-icon>
              Filtrar por etiquetas
            </v-btn>
          </div>
        </template>

        <v-card>
          <v-toolbar
            dark
            color="pink"
          >
            <v-btn
              icon
              dark
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Filtros</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn
                dark
                text
                @click="sendFilters()"
              >
                Aplicar filtros
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-title>Selecciona los filtros que quieras usar</v-card-title>
          <v-card-text>
            <v-list class="pt-0 pl-0">
              <v-list-item v-for="tag in tags" :key="tag.id">
                <v-checkbox dense v-model="selectedTagIds" :value="tag.id" :label="tag.name" />
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-divider />
          <v-card-actions class="justify-center mt-3">
            <v-btn
              dark
              color="pink"
              @click="sendFilters()"
            >
              Aplicar filtros
            </v-btn>
          </v-card-actions>

        </v-card>
      </v-dialog>

      <v-card-text v-if="selectedTagIds.length" class="pt-3 pa-0">
        Filtros seleccionados:
        <v-chip small v-for="tag in selectedTags" :key="tag.id" class="mb-1 mr-1">
          {{ tag.name }}
        </v-chip>
      </v-card-text>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import Constants from "@/constants"

export default {
  name: "FilterForm",
  props: {
    filtersApplied: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      url: Constants.API_URL,
      username: Constants.USERNAME,
      selectedTagIds: [],
      tags: [],
      changed: false,
      dialog: false,
    }
  },
  computed: {
    disableButton() {
      if (this.filtersApplied) {
        if (this.changed) {
          return false;
        }
        return true;
      }
      return false;
    },
    selectedTags() {
      return this.tags.filter(tag => this.selectedTagIds.includes(tag.id))
    },
  },

  methods: {
    getTags() {
      axios.get(`${this.url}/api/v1/catalogue/${this.username}/tags/`).then((response) => {
        // this.tags = response.data.results.map(tag => tag.name);
        this.tags = response.data.results;
      })
      .catch((error) => {
        console.error(error)
        this.error = true;
        this.loading = false;
      });
    },
    sendFilters() {
      // const ids = this.selectedTagIds.map(tag => this.tags.find(t => t.name == tag).id);
      this.changed = false;
      this.$emit("filtros", this.selectedTagIds);
      this.dialog = false;
    },
    remove (obj) {
      const index = this.selectedTagIds.indexOf(obj.name);
      if (index >= 0) this.selectedTagIds.splice(index, 1)
    },
  },
  beforeMount() {
    this.getTags();
  },
  watch: {
    selectedTagIds: {
      handler() {
        this.changed = true;
      },
      deep: true
    }
  }
}
</script>

<style>
.v-list-item {
  min-height: 35px !important;
  padding: 0 !important;
}
.v-input {
  margin: 0;
  padding: 0;;
}
.v-input .v-messages {
  min-height: 0;
}
</style>