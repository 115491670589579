import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

const state = {
  total: (localStorage.total) ? parseInt(localStorage.getItem("total")) : 0,
  cart: (localStorage.cart) ? JSON.parse(localStorage.getItem("cart")) : {},
  products: [],
}

const getters = {
  cartTotal(state) {
    return state.total;
  },
  cart(state) {
    return state.cart;
  },
  products(state) {
    return state.products;
  },
};

const mutations = {
  setProducts: (state, products) => {
    state.products = products;
  },
  addToCart: (state, product_id) => {
    // Vue.set(state.cart, product_id, 0) // this is reactive
    // state.cart[product_id] = 0;  // this is not reactive
    
    if (!state.cart[product_id]) {
      Vue.set(state.cart, product_id, 0);
    }

    Vue.set(state.cart, product_id, state.cart[product_id] + 1)

    state.total += 1;

    localStorage.setItem("cart", JSON.stringify(state.cart));
    localStorage.setItem("total", state.total);
  },
  removeFromCart (state, product_id) {
    state.total -= state.cart[product_id];
    Vue.delete(state.cart, product_id);

    localStorage.setItem("cart", JSON.stringify(state.cart));
    localStorage.setItem("total", state.total);
  },
  clear (state) {
    state.total = 0;
    state.cart = {};
    localStorage.clear();
  },
};



export default new Vuex.Store({
  state,
  mutations,
  getters,
})