<template>
  <div>
    <v-badge
      overlap
      :content="cartTotal"
      :value="cartTotal"
    >
      <v-btn
        icon
        small
        @click="dialogShowCart = true"
      >
        <v-icon>mdi-cart</v-icon>
      </v-btn>
    </v-badge>
    <v-dialog
      v-model="dialogShowCart"
      max-width="460"
      scrollable
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card elevation="2">
        <v-card-title class="px-4">
          <span>Carrito</span>
          <v-spacer />
          <v-icon @click="dialogShowCart = false">mdi-close</v-icon>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-4 px-4">
          <p v-if="selectedProducts.length">Pregúnta por estos productos a través de Whatsapp</p>
          <v-simple-table class="text-left" dense v-if="selectedProducts.length">
            <template v-slot:default>
              <thead>
                <tr>
                  <th></th>
                  <th>Cant.</th>
                  <th>Producto</th>
                  <th v-if="showPrices" class="text-right">Precio</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="product in selectedProducts" :key="product.id">
                  <td class="mx-0 px-0 text-center">
                    <v-btn icon color="red darken-2" @click="removeFromCart(product.id)">
                      <v-icon small>mdi-close</v-icon>
                    </v-btn>
                  </td>
                  <td>{{ amount(product.id) }}</td>
                  <td>{{ product.name }} <div class="text-muted" style="font-size: 0.65rem">[{{ product.reference }}]</div></td>
                  <td v-if="showPrices" class="text-right">
                    ${{ (amount(product.id) * product.sale_price).toLocaleString('ES-co') }}
                  </td>
                </tr>
                <tr>
                  <td colspan="3" class="text-lg">{{ cartTotal }} artículos</td>
                  <th v-if="showPrices" class="text-right text-lg">${{ totalAmount.toLocaleString('ES-co') }}</th>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <p class="mb-0" v-else>Carrito vacío</p>
        </v-card-text>
        <v-card-actions class="d-block text-center pt-8" v-if="selectedProducts.length">
          <div>
            <v-btn
              color="#25D366"
              class="white--text"
              large
              @click="sendCart()"
            >
              <v-icon class="mr-2">mdi-whatsapp</v-icon>
              Enviar carrito
            </v-btn>
          </div>
          <div class="text-muted my-3">
            <v-btn text @click="clear()" small>Vaciar carrito</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { filter, find, includes, keys, map, sum } from "lodash";
import { mapGetters, mapMutations } from "vuex";
import Constants from '@/constants';

export default {
  name: "ShoppingCart",

  data: () => ({
    dialogShowCart: false,
    showPrices: true,
    WHATSAPP_NUMBER: Constants.WHATSAPP_NUMBER,
    BUSINESS_NAME: Constants.BUSINESS_NAME,
  }),

  computed: {
    ...mapGetters({
      cartTotal: "cartTotal",
      cart: "cart",
      products: "products"
    }),

    selectedProductIds() {
      return keys(this.cart);
    },

    selectedProducts() {
      return filter(this.products, product => includes(this.selectedProductIds, `${product.id}`));
    },
    totalAmount() {
      const totals = map(this.cart, (amount, product_id) => {
        const product = find(this.products, product => product.id == product_id);
        return product.sale_price * parseInt(amount)
      });
      return sum(totals);
    },
  },
  beforeMount() {
    const hash = new URL(location.href).hash;
    if (hash) {
      this.showPrices = false;
    }
  },
  methods: {
    ...mapMutations({
      removeFromCart: "removeFromCart"
    }),
    clear() {
      this.$store.commit("clear");
      this.dialogShowCart = false;
    },
    amount(product_id) {
      // console.log(this.cart, product_id)
      return this.cart[product_id];
    },
    capitalize(text) {
      return "```" + text.charAt(0).toUpperCase() + text.toLowerCase().slice(1) + "```";
    },
    sendCart() {
      this.dialogShowCart = false;
      let message = `Hola *${this.BUSINESS_NAME}.* %0D%0A%0D%0A
Me interesan los siguientes productos:%0D%0A%0D%0A

${map(this.selectedProducts, p => `· *${this.amount(p.id)}* x ${this.capitalize(p.name)} _[${p.reference}]_ ${this.showPrices ? ` = _$${(this.amount(p.id) * p.sale_price).toLocaleString('ES-co')}_` : ''}`).join("%0D%0A")}
`;
      if (this.showPrices) message += `%0D%0A%0D%0APara un total de *$${this.totalAmount.toLocaleString('ES-co')}*`
      message += `%0D%0A%0D%0AGracias por su información.`;
      window.location.href = `https://wa.me/${this.WHATSAPP_NUMBER.replaceAll(" ", "")}/?text=${message}`;
    },
  },
}
</script>

<style scoped>
.text-lg {
  font-size: 1.25rem!important;
}
.text-muted {
  color: #888;
}
</style>
