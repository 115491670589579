<template>
  <v-app-bar
    app
    color="pink"
    dark
  >
    <div class="d-flex align-center">
      <v-img
        alt="Distribuidora Un Mundo de Belleza"
        class="shrink mr-2"
        contain
        :src="require('@/assets/logotipo.png')"
        transition="scale-transition"
        width="40"
      />
      {{ BUSINESS_NAME }}
    </div>

    <v-spacer></v-spacer>

    <ShoppingCart />
    <v-btn
      :href="`https://wa.me/${WHATSAPP_NUMBER.replaceAll(' ', '')}`"
      target="_blank"
      text
      class="ml-3 pl-1 pr-0"
    >
      <v-icon class="mr-1">mdi-whatsapp</v-icon>
      <span v-text="WHATSAPP_NUMBER"></span>
    </v-btn>
  </v-app-bar>
</template>

<script>
import ShoppingCart from '@/components/ShoppingCart';
import Constants from '@/constants';

export default {
  name: "AppBar",
  data: () => ({
    WHATSAPP_NUMBER: Constants.WHATSAPP_NUMBER,
    BUSINESS_NAME: Constants.BUSINESS_NAME,
  }),
  beforeMount() {
  },
  props: {
    total: {
      type: Number,
      default: 0,
    },
  },
  components: {
    ShoppingCart,
  },
};
</script>
